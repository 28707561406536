import React, { useEffect, useContext } from "react";
import { Route, withRouter } from "react-router-dom";
import routes from "./../../config/app-route.jsx";
import { AppSettings } from "./../../config/app-settings.js";
import UnauthorizeRoute from "../../config/Unauthorize-app-route.jsx";
 
function setTitle(path, routeArray) {
  var appTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      appTitle = "Click Install | " + routeArray[i].title;
    }
  }
  document.title = appTitle ? appTitle : "Click Install  ";
}

const Content = ({ history }) => {
  const appSettings = useContext(AppSettings);
 
  useEffect(() => {
    setTitle(history.location.pathname, routes);
 
    const unlisten = history.listen(() => {
      setTitle(history.location.pathname, routes);
    });

    return () => {
      unlisten();
    };
  }, [history]);

 
  const isAuthenticated = !! localStorage.getItem("user");

  return (
    <div className={"app-content " + appSettings.appContentClass}>
      {isAuthenticated
        ? routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))
        : UnauthorizeRoute.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
    </div>
  );
};

export default withRouter(Content);
