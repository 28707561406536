import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import axios from 'axios';

class Register extends React.Component {
	static contextType = AppSettings;

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			redirect: false
		}
	}

	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentClass('p-0');
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentClass('');
	}

	async handleSubmit(event) {
		event.preventDefault();
		const email = document.getElementById("txt_emailAddress").value;
		const confirmEmail = document.getElementById("txt_reEnterEmail").value;
		const firstName = document.getElementById("txt_firstName").value;
		const lastName = document.getElementById("txt_lastName").value;
		const password = document.getElementById("txt_password").value;
		debugger;
		try {
			if (confirmEmail === email) {
				await axios.post("https://backend.clickperinstall.com/Publisher/Register", {
					FullName: firstName + " " + lastName,
					Email: email,
					Password: password,
					Status: false,
				});
				alert("Your Account has Created. Please Contact Admin to Approve Your Account.");
				window.location.reload();
				this.setState({ redirect: true });
			}
			else {
				alert("Email and Confirm Email should be same.");
			}
		} catch (error) {
			console.error("", error);
		}
	}
	render() {
		if (this.state.redirect) {
			return <Redirect to='/dashboard' />;
		}

		return (
			<div className="register register-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-9.jpg)' }}></div>
					<div className="news-caption">
						<h4 className="caption-title"><b>Button</b> Mine</h4>
						<p>
							Click Per Install is a Trending ad network in pay per install industry. We promise to give rates overall with our dynamic system.</p>
					</div>
				</div>
				<div className="register-container">
					<div className="register-header mb-25px h1">
						<div className="mb-1">Sign Up</div>
						<small className="d-block fs-15px lh-16">Create your Click Per Install Account.</small>
					</div>
					<div className="register-content">
						<form onSubmit={this.handleSubmit} className="fs-13px">
							<div className="mb-3">
								<label className="mb-2">Name <span className="text-danger">*</span></label>
								<div className="row gx-3">
									<div className="col-md-6 mb-2 mb-md-0">
										<input type="text" id="txt_firstName" className="form-control fs-13px" placeholder="First name" />
									</div>
									<div className="col-md-6">
										<input type="text" id="txt_lastName" className="form-control fs-13px" placeholder="Last name" />
									</div>
								</div>
							</div>
							<div className="mb-3">
								<label className="mb-2">Email <span className="text-danger">*</span></label>
								<input type="text" id="txt_emailAddress" className="form-control fs-13px" placeholder="Email address" />
							</div>
							<div className="mb-3">
								<label className="mb-2">Re-enter Email <span className="text-danger">*</span></label>
								<input type="text" id="txt_reEnterEmail" className="form-control fs-13px" placeholder="Re-enter email address" />
							</div>
							<div className="mb-4">
								<label className="mb-2">Password <span className="text-danger">*</span></label>
								<input type="password" id="txt_password" className="form-control fs-13px" placeholder="Password" />
							</div>
							<div className="form-check mb-4">
								<input className="form-check-input" type="checkbox" value="" id="agreementCheckbox" />
								<label className="form-check-label" htmlFor="agreementCheckbox">
									By clicking Sign Up, you agree to our <Link to="/user/register-v3">Terms</Link> and that you have read our <Link to="/user/register-v3">Data Policy</Link>, including our <Link to="/user/register-v3">Cookie Use</Link>.
								</label>
							</div>
							<div className="mb-4">
								<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">Sign Up</button>
							</div>
							<div className="mb-4 pb-5">
								Already a member? Click <Link to="/user/login">here</Link> to login.
							</div>
							<hr className="bg-gray-600 opacity-2" />
							<p className="text-center text-gray-600">
								&copy; ClickPerInstall. All Rights Reserved
							</p>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default Register;