import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/dashboard/dashboard.js";
import UpdatePublisher from "../pages/publisher/updatePublisher.js";
import PublishersWEB from "../pages/publisher/publisherWeb.js";
import PublisherButtons from "../pages/publisher/publisherButton.js";
import PublisherPassword from "../pages/publisher/publisherPassword.js";
import PublisherReport from "../pages/publisher/publisherReport.js";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    component: () => <Dashboard />,
  },   
  {
    path: "/updatePublisher",
    title: "Update Publisher",
    component: () => <UpdatePublisher />,
  },
  {
    path: "/publisherWeb",
    title: "Publisher Websites",
    component: () => <PublishersWEB />,
  },
  {
    path: "/publisherPassword",
    title: "Publisher Password",
    component: () => <PublisherPassword />,
  },
  {
    path: "/publisherReport",
    title: "Publisher Report",
    component: () => <PublisherReport />,
  },
  {
    path: "/publisherButton",
    title: "Publisher Buttons",
    component: () => <PublisherButtons />,
  },
  {
    path: "*",
    component: () => <Redirect to="/dashboard" />,
  },
];

export default routes;
